<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";
import { User, Settings, Home, LayoutDashboard } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const route = useRoute();
const showProfile = ref(false);
const showSettings = ref(false);

const studyTechniques = [
  { label: "Pomodoro Technique", value: "pomodoro" },
  { label: "Spaced Repetition", value: "spaced_repetition" },
  { label: "Active Recall", value: "active_recall" },
  { label: "Feynman Technique", value: "feynman" },
  { label: "Mind Mapping", value: "mind_mapping" },
  { label: "SQ3R Method", value: "sq3r" },
];

type NavItem = {
  title: string;
  href: string;
  icon: any;
  action?: () => void;
  position?: "right";
};

const navItems: NavItem[] = [
  { title: "Home", href: "/", icon: Home },
  { title: "Overview", href: "/overview", icon: LayoutDashboard },
  {
    title: "Settings",
    href: "#",
    icon: Settings,
    action: () => (showSettings.value = true),
    position: "right",
  },
  {
    title: "Profile",
    href: "#",
    icon: User,
    action: () => (showProfile.value = true),
    position: "right",
  },
];
</script>

<template>
  <header class="bg-indigo-700 dark:bg-indigo-900 text-white py-4">
    <div class="container mx-auto px-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-6">
          <h1 class="text-2xl font-bold">
            <NuxtLink to="/">
              <img
                src="/img/studyplnr.png"
                alt="StudyPlnr Logo"
                class="h-6 md:h-8 lg:h-12 w-auto brightness-[6]"
              />
            </NuxtLink>
          </h1>
          <!-- Desktop Navigation - Left Side -->
          <div class="hidden md:flex items-center gap-4">
            <NuxtLink
              v-for="item in navItems.filter((item) => !item.position)"
              :key="item.href"
              :to="item.action ? undefined : item.href"
              :class="
                cn(
                  'text-white hover:bg-indigo-600 px-3 py-2 rounded-md text-sm font-medium inline-flex items-center gap-2 leading-none',
                  route.path === item.href ? 'bg-indigo-600' : ''
                )
              "
              @click="item.action && item.action()"
            >
              <component :is="item.icon" class="size-4" />
              <span class="mt-1">{{ item.title }}</span>
            </NuxtLink>
          </div>
        </div>
        <!-- Desktop Navigation - Right Side -->
        <div class="hidden md:flex items-center gap-4">
          <NuxtLink
            v-for="item in navItems.filter((item) => item.position === 'right')"
            :key="item.href"
            :to="item.action ? undefined : item.href"
            @click="item.action && item.action()"
            :class="
              cn(
                'text-white hover:bg-indigo-600 px-3 py-2 rounded-md text-sm font-medium inline-flex items-center gap-2 leading-none',
                route.path === item.href ? 'bg-indigo-600' : ''
              )
            "
          >
            <component :is="item.icon" class="size-4" />
            <span class="mt-1">{{ item.title }}</span>
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- Mobile Navigation -->
    <nav
      class="md:hidden fixed bottom-0 left-0 right-0 bg-white dark:bg-stone-900 border-t border-gray-200 dark:border-stone-800 py-2 z-50"
    >
      <ul class="flex justify-around items-center">
        <li v-for="item in navItems" :key="item.href">
          <NuxtLink
            :to="item.action ? undefined : item.href"
            @click="item.action && item.action()"
            class="flex flex-col items-center"
            :class="
              cn(
                'text-gray-500 dark:text-gray-400',
                route.path === item.href
                  ? 'text-indigo-600 dark:text-indigo-400'
                  : ''
              )
            "
          >
            <component :is="item.icon" class="h-6 w-6" />
            <span class="text-xs mt-1">{{ item.title }}</span>
          </NuxtLink>
        </li>
      </ul>
    </nav>

    <!-- Dialogs -->
    <Dialog v-model:open="showProfile">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>User Profile</DialogTitle>
          <DialogDescription>
            Update your profile information here.
          </DialogDescription>
        </DialogHeader>
        <UserProfile @close="showProfile = false" />
      </DialogContent>
    </Dialog>

    <Dialog v-model:open="showSettings">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>User Settings</DialogTitle>
          <DialogDescription>
            Customize your study preferences and app settings.
          </DialogDescription>
        </DialogHeader>
        <UserSettings
          :study-techniques="studyTechniques"
          @close="showSettings = false"
        />
      </DialogContent>
    </Dialog>
  </header>
</template>
