<script setup lang="ts">
import { useStorage } from "@vueuse/core";

const userString = useStorage<string>("user", null);
const theme = computed(() => {
  if (userString.value) {
    const user = JSON.parse(userString.value);
    return user.theme || "light";
  }
  return "light";
});

watch(
  theme,
  (newTheme) => {
    if (newTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="flex flex-col min-h-screen bg-indigo-50 dark:bg-stone-950 text-gray-900 dark:text-gray-100"
  >
    <TheHeader />
    <main class="flex-grow">
      <slot />
    </main>
    <TheFooter class="pb-20 md:pb-3" />
  </div>
</template>

<style>
html,
body {
  height: 100%;
}
</style>
